<template>
  <b-card no-body class="border-0 p-2">
    <div class="d-flex flex-wrap">
      <div class="mx-auto">
        <b-img rounded class="m-auto member-photo" :blank="!picture" blank-color="#777" :src="picture" fluid />
      </div>
      <div class="flex-fill flex-grow-1 pl-sm-3">
        <b-card-title class="m-auto"> {{ member.fullName }} </b-card-title>
        <b-table-simple small borderless class="m-0">
          <b-tr v-for="(field, i) in fields" :key="i">
            <b-th class="p-0 text-nowrap" :colspan="1 === field.length ? 2 : 1">
              <!-- If the field is a key/value pair, include a colon -->
              {{ field[0] + (1 === field.length ? '' : ':') }}
            </b-th>
            <b-td class="w-100 py-0 px-2">{{ field[1] }}</b-td>
          </b-tr>
        </b-table-simple>
        <div class="mt-3">
          <b-link :href="tvcLink">
            <strong>My Training Verification Card</strong>
          </b-link>
        </div>
        <div>
          <b-link @click="downloadTrainingHistory">
            <small>[Download Training History]</small>
          </b-link>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import downloader from '@/util/downloader';
import { format as dateFormat } from '@/util/date';

@Component
export default class MemberDetails extends Vue {
  get fields() {
    return [
      [this.member.ubcId],
      ['Local', this.member.affiliate],
      ['Classification', this.member.classification],
      ['Craft', this.member.craft],
      ['Paid Through Date', dateFormat(this.member.paidThroughDate, 'MMMM d, yyyy')],
      ['Initiation Date', dateFormat(this.member.initDate, 'MMMM d, yyyy')],
      ['Union Status', this.member.status]
    ];
  }

  get user() {
    return this.$store.getters['session/currentUser'] || {};
  }

  get ubcId() {
    return this.user.ubcId;
  }

  get member() {
    const mem = this.$store.getters.currentMember;
    return !!mem && mem.isValid ? mem : {};
  }

  get picture() {
    return this.$store.getters.memberPicture;
  }

  get meta() {
    return this.$store.getters.meta || {};
  }

  get env() {
    return this.meta.env || 'dev';
  }

  get tvcLink() {
    return this.$store.getters.tvcLink;
  }

  async downloadTrainingHistory() {
    const ubcId = this.ubcId;

    if (!ubcId) {
      return;
    }

    try {
      await downloader.get(`/api/report/member/training_history/${ubcId}`, 'training_history.pdf');
    } catch (err) {
      this.$bvToast.toast(err.message, {
        title: 'Error',
        solid: true,
        variant: 'danger'
      });
    }
  }
}
</script>
<style scoped>
div.link-small {
  font-size: 10px;
}

img.member-photo {
  border: 1px solid black;
  height: 200px;
  max-width: 150px;
}

.more-info-link {
  cursor: pointer;
}
</style>
