<template>
  <b-card no-body class="border-0">
    <b-card-header>
      <div class="d-flex">
        <strong class="flex-grow-1">Upgrade Information</strong>
        <b-button variant="link" @click="show = !show">
          <b-icon :icon="'chevron-double-' + (show ? 'up' : 'down')" />
        </b-button>
      </div>
    </b-card-header>
    <b-collapse v-model="show">
      <b-row no-gutters cols-md="2" cols="1">
        <b-col class="p-2" v-if="showNeededForNextUpgrade">
          <b-row>
            <b-col><b-alert show variant="info">Needed for Next Upgrade</b-alert></b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.UPGRADE, fieldValueKeys.UPGRADE.LAST_DATE)">
            <b-col><strong>Last Upgrade Date:</strong></b-col>
            <b-col>{{ formatDate(member.lastUpgradeDate, 'MMMM d, yyyy') }}</b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.UPGRADE, fieldValueKeys.UPGRADE.NEXT_DATE)">
            <b-col><strong>Next Upgrade Date:</strong></b-col>
            <b-col>{{ formatDate(member.nextUpgradeDate, 'MMMM d, yyyy') }}</b-col>
          </b-row>
          <b-row cols="2" v-if="!isCombined && hasDisplayField(fieldKeys.UPGRADE, fieldValueKeys.UPGRADE.WORK_HOURS)">
            <b-col><strong>Work Hours:</strong></b-col>
            <b-col>{{ member.upgradeWorkHours }}</b-col>
          </b-row>
          <b-row cols="2" v-if="showUpgradeField">
            <b-col>
              <strong>{{ upgradeLabel }}:</strong>
            </b-col>
            <b-col>{{ member.classUpgrade }}</b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.UPGRADE, fieldValueKeys.UPGRADE.REQUIRED)">
            <b-col><strong>Required Classes:</strong></b-col>
            <b-col>{{ member.requiredClasses }}</b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.UPGRADE, fieldValueKeys.UPGRADE.OTHER)">
            <b-col><strong>Other Upgrade Requirements:</strong></b-col>
            <b-col>{{ member.otherUpgradeRequirements }}</b-col>
          </b-row>
        </b-col>
        <b-col class="p-2" v-if="showExperienceNeededForGraduation">
          <b-row>
            <b-col><b-alert show variant="info">Experience Needed to Graduate</b-alert></b-col>
          </b-row>
          <b-row cols="2" v-if="!isCombined && hasDisplayField(fieldKeys.EXPERIENCE, fieldValueKeys.EXPERIENCE.WORK_HOURS)">
            <b-col><strong>Number of On-the-Job Training Hours:</strong></b-col>
            <b-col>{{ member.neededWorkHours }}</b-col>
          </b-row>
          <b-row cols="2" v-if="showExperienceField">
            <b-col>
              <strong>{{ neededLabel }}:</strong>
            </b-col>
            <b-col>{{ member.classNeeded }}</b-col>
          </b-row>
          <b-row cols="2">
            <b-col><strong>Expected Graduation Date:</strong></b-col>
            <b-col>{{ formatDate(member.expectedGraduationDate, 'MMMM d, yyyy') }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import { format as formatDate } from '@/util/date';
import { DisplayField } from '@/pages/training/mixins/display_field';

@Component({
  mixins: [DisplayField]
})
export default class UpgradeInformation extends Vue {
  show = true;

  get showNeededForNextUpgrade() {
    const keys = this.fieldValueKeys.UPGRADE;
    return (
      [keys.LAST_DATE, keys.NEXT_DATE, keys.WORK_HOURS, keys.REQUIRED, keys.OTHER].some((key) => this.hasDisplayField(this.fieldKeys.UPGRADE, key)) ||
      this.showUpgradeField
    );
  }

  get showUpgradeField() {
    const keys = this.fieldValueKeys.UPGRADE;
    return [keys.CLASS_HOURS, keys.CLASS_CREDITS, keys.CLASS_UNITS, keys.COMBINED_HOURS].some((key) =>
      this.hasDisplayField(this.fieldKeys.UPGRADE, key)
    );
  }

  get showExperienceNeededForGraduation() {
    const keys = this.fieldValueKeys.EXPERIENCE;
    return (
      [keys.WORK_HOURS, keys.EXPECTED_GRADUATION].some((key) => this.hasDisplayField(this.fieldKeys.EXPERIENCE, key)) || this.showExperienceField
    );
  }

  get showExperienceField() {
    const keys = this.fieldValueKeys.EXPERIENCE;
    return [keys.CLASS_HOURS, keys.CLASS_UNITS, keys.CLASS_CREDITS, keys.COMBINED_HOURS].some((key) =>
      this.hasDisplayField(this.fieldKeys.EXPERIENCE, key)
    );
  }

  get upgradeLabel() {
    switch ((this.member.classTypeRequirement || '').toUpperCase()) {
      case 'COMBINED':
        return 'Combined Work/Class Hours';
      case 'CREDITS':
        return 'Class Credits';
      case 'UNITS':
        return 'Class Units';
      default:
        return 'Class Hours';
    }
  }

  get isCombined() {
    return 'COMBINED' === (this.member.classTypeRequirement || '').toUpperCase();
  }

  get neededLabel() {
    switch ((this.member.classTypeRequirement || '').toUpperCase()) {
      case 'COMBINED':
        return 'Number of Combined Work/Class Hours';
      case 'CREDITS':
        return 'Number of Class Credits';
      case 'UNITS':
        return 'Number of Class Units';
      default:
        return 'Number of Class Hours';
    }
  }

  formatDate(date, pattern) {
    return formatDate(date, pattern);
  }
}
</script>
