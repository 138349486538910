<template>
  <b-card no-body class="border-0">
    <template #header>
      <div class="d-flex">
        <strong class="flex-grow-1">Shortcuts &amp; Links to Other Websites</strong>
        <b-button variant="link" @click="showQuickLinks = !showQuickLinks">
          <b-icon :icon="'chevron-double-' + (showQuickLinks ? 'up' : 'down')" />
        </b-button>
      </div>
    </template>
    <b-collapse v-model="showQuickLinks">
      <b-list-group class="d-block d-sm-none" flush>
        <b-list-group-item :to="{ name: 'contact-information-form' }">
          <b-icon-person-lines-fill class="small-link-icon" />
          Update Contact Information
        </b-list-group-item>
        <b-list-group-item v-for="(link, i) in links" :key="i" :href="link.url" target="_blank">
          <b-img :src="link.logo" v-if="link.logo" class="small-link-icon" />
          <b-icon :icon="link.bootstrap_icon" v-if="link.bootstrap_icon" class="small-link-icon" />
          {{ link.text }}
        </b-list-group-item>
        <b-list-group-item :to="{ name: 'training_link', params: { page: 'membership' } }">
          <b-icon-people-fill class="small-link-icon" />
          Membership
        </b-list-group-item>
        <b-list-group-item :to="{ name: 'training_link', params: { page: 'apprenticeship' } }" v-if="$store.getters.isApprentice">
          <b-icon-hammer class="small-link-icon" />
          Apprenticeship
        </b-list-group-item>
        <b-list-group-item :href="tvcLink" target="_blank">
          <b-icon-person-badge class="small-link-icon" />
          My Training Verification Card
        </b-list-group-item>
        <b-list-group-item :to="{ name: 'training_link', params: { ubcId, page: 'training-history' } }">
          <b-icon-card-list class="small-link-icon" />
          Training History
        </b-list-group-item>
        <b-list-group-item :to="{ name: 'time_card', params: { ubcId } }">
          <b-icon-clock-history class="small-link-icon" />
          Time Card
        </b-list-group-item>
        <b-list-group-item :to="{ name: 'training_link', params: { ubcId, page: 'class-registration' } }" v-if="showOMR">
          <b-icon-clipboard-check class="small-link-icon" />
          Local Class Registration
        </b-list-group-item>
        <b-list-group-item :to="{ name: 'training_link', params: { ubcId, page: 'required-curriculum' } }" v-if="showCurriculum">
          <b-icon-list-check class="small-link-icon" />
          Required Curriculum
        </b-list-group-item>
      </b-list-group>
      <b-card-body class="d-none d-sm-block">
        <b-skeleton-wrapper :loading="!quickLinksLoaded">
          <template #loading>
            <b-card-group deck>
              <b-card class="m-0 border-0" v-for="i in Array(5).keys()" :key="i">
                <b-skeleton-img width="75px" height="75px" no-aspect></b-skeleton-img>
              </b-card>
            </b-card-group>
          </template>
          <b-card-group deck>
            <quick-link icon="person-lines-fill" :to="{ name: 'contact-information-form' }" label="Update Contact Information" />
            <quick-link
              v-for="(link, i) in links"
              :key="i"
              align="center"
              class="m-0 border-0"
              :icon="link.bootstrap_icon"
              :img="link.logo"
              :img-width="link.width"
              :img-height="link.height"
              :href="link.url"
              target="_blank"
              :label="link.text" />
            <quick-link icon="people-fill" :to="{ name: 'training_link', params: { page: 'membership' } }" label="Membership" />
            <quick-link icon="hammer" :to="{ name: 'training_link', params: { page: 'apprenticeship' } }" label="Apprenticeship" v-if="$store.getters.isApprentice" />
            <quick-link icon="person-badge" :href="tvcLink" target="_blank" label="My Training Verification Card" />
            <quick-link icon="card-list" :to="{ name: 'training_link', params: { ubcId, page: 'training-history' } }" label="Training History" />
            <quick-link icon="clock-history" :to="{ name: 'time_card', params: { ubcId } }" label="Time Card" />
            <quick-link
              icon="clipboard-check"
              :to="{ name: 'training_link', params: { ubcId, page: 'class-registration' } }"
              label="Local Class Registration" v-if="showOMR" />
            <quick-link
              icon="list-check"
              :to="{ name: 'training_link', params: { ubcId, page: 'required-curriculum' } }"
              v-if="showCurriculum"
              label="Required Curriculum" />
          </b-card-group>
        </b-skeleton-wrapper>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import YouAreHere from '@/components/YouAreHere';
import QuickLink from '@/components/QuickLink';
import _ from 'underscore';

@Component({
  components: { YouAreHere, QuickLink }
})
export default class QuickLinks extends Vue {
  showQuickLinks = true;

  get quickLinksLoaded() {
    return this.$store.getters['quickLinks/loaded'];
  }

  get links() {
    return _(this.$store.getters['quickLinks/links'] || []).sortBy((o) => o.ordinal);
  }

  get user() {
    return this.$store.getters['session/currentUser'] || {};
  }

  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get showOMR() {
    return this.$store.getters['omr/enableOMR'];
  }

  get showCurriculum() {
    return this.$store.getters['omr/requireCurriculum'] && 'APPRENTICE' === (this.currentMember.trainClassification || '').toUpperCase();
  }

  get ubcId() {
    return this.user.ubcId;
  }

  get tvcLink() {
    return this.$store.getters.tvcLink;
  }

  async mounted() {
    if (this.links.length == 0) {
      await this.$store.dispatch('quickLinks/getQuickLinks');
    }
  }
}
</script>
<style scoped>
.small-link-icon {
  color: black;
  width: 20px;
  height: 20px;
}
</style>
