import { FIELD_KEYS, FIELD_VALUE_KEYS } from '@/model/training_center';

export const DisplayField = {
  computed: {
    fieldKeys() {
      return this.$store.getters.fieldKeys;
    },

    fieldValueKeys() {
      return this.$store.getters.fieldValueKeys;
    },

    trainingCenter() {
      const tc = this.$store.getters.trainingCenter;
      return !!tc && tc.isValid ? tc : {};
    },

    member() {
      const mem = this.$store.getters.currentMember;
      return !!mem && mem.isValid ? mem : {};
    },

  },

  methods: {
    hasDisplayField(key, field) {
      return this.$store.getters.hasDisplayField(this.trainingCenter, key, field);
    },
  }
};
