<template>
  <b-card no-body bg-variant="light" class="m-3">
    <template v-if="hasTrainingCenter">
      <b-card-body>
        <b-card-title>{{ trainingCenter.name }}</b-card-title>
        <b-list-group flush>
          <b-list-group-item class="bg-light p-0 border-0" v-for="(line, i) in lines" :key="i">{{ line }}</b-list-group-item>
        </b-list-group>
      </b-card-body>
    </template>
    <template v-else>
      <b-card-body>
        <b-card-title> No Training Center has been assigned to your profile. </b-card-title>
        Please contact your
        <b-link href="https://www.carpenters.org/training-center" alt="Link to Available Training Centers" target="_blank">Training Center</b-link>.
      </b-card-body>
    </template>
  </b-card>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import _ from 'underscore';

@Component
export default class TrainingCenterDetails extends Vue {
  get lines() {
    const lines = [this.trainingCenter.address1];

    if (!!this.trainingCenter.address2) {
      lines.push(this.trainingCenter.address2);
    }

    const address = this.formattedCityStateZip;

    if (!!address) {
      lines.push(address);
    }

    const phone = this.formattedPhoneNumber;

    if (!!phone) {
      lines.push(phone);
    }

    if (!!this.trainingCenter.emailAddress) {
      lines.push(this.trainingCenter.emailAddress);
    }

    return lines;
  }
  get hasTrainingCenter() {
    return !_(this.trainingCenter).isEmpty();
  }

  get trainingCenter() {
    const tc = this.$store.getters.trainingCenter;
    return !!tc && tc.isValid ? tc : {};
  }

  get formattedCityStateZip() {
    if (!this.hasTrainingCenter) {
      return '';
    }

    const tc = this.trainingCenter;
    return `${tc.city}, ${tc.state}  ${tc.zip}`;
  }

  get formattedPhoneNumber() {
    const tc = this.trainingCenter;
    const ph = ((tc || {}).phoneNumber || '').trim();
    return _(ph).isEmpty() ? '' : ph.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
  }
}
</script>
