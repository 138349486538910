<template>
  <b-card no-body class="p-0 m-0">
    <b-tabs small card @activate-tab="onTabChanged">
      <membership-view ref="tab0" />
      <apprenticeship-view ref="tab1" v-if="$store.getters.isApprentice" />
      <registration ref="tab2" v-if="showOMR" />
      <training-history-view ref="tab3" />
      <required-curriculum-view ref="tab4" v-if="showCurriculum" />
    </b-tabs>
  </b-card>
</template>
<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MembershipView from '@/pages/training/views/MembershipView';
import ApprenticeshipView from '@/pages/training/views/ApprenticeshipView';
import Registration from '@/pages/training/views/Registration';
import TrainingHistoryView from '@/pages/training/views/TrainingHistoryView';
import RequiredCurriculumView from '@/pages/training/views/RequiredCurriculumView';
import _ from 'underscore';

const PAGES = {
  MEMBERSHIP: 'membership',
  APPRENTICESHIP: 'apprenticeship',
  CLASS_REGISTRATION: 'class-registration',
  TRAINING_HISTORY: 'training-history',
  REQUIRED_CURRICULUM: 'required-curriculum'
};

const DEFAULT_PAGE = PAGES.MEMBERSHIP;
const TABS_INDEXES = _(PAGES).values();

@Component({
  metaInfo: {
    title: 'Training'
  },
  components: {
    MembershipView,
    ApprenticeshipView,
    Registration,
    TrainingHistoryView,
    RequiredCurriculumView
  }
})
export default class Training extends Vue {
  @Prop({ type: String, default: DEFAULT_PAGE }) page;

  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get trainingCenter() {
    return this.$store.getters.trainingCenter || {};
  }

  get showCurriculum() {
    return this.$store.getters['omr/requireCurriculum'];
  }

  get showOMR() {
    return this.$store.getters['omr/enableOMR'];
  }

  get trainingCenterIsLoaded() {
    return this.trainingCenter.isValid;
  }

  activateTab(idx) {
    this.$refs[`tab${idx}`]?.activate?.();
  }

  @Watch('trainingCenterIsLoaded')
  onTrainingCenterLoaded(loaded) {
    if (loaded) {
      setTimeout(() => this.activateTab(TABS_INDEXES.indexOf(this.page)), 200);
    }
  }

  @Watch('$route')
  onRouteChange(r) {
    const idx = TABS_INDEXES.indexOf(r?.params?.page);

    if (0 > idx) {
      return;
    }

    this.activateTab(idx);
  }

  onTabChanged(n) {
    if (!_(TABS_INDEXES).has(n)) {
      return;
    }

    const page = TABS_INDEXES[n];
    const previousPage = this.$route.params?.page;

    if (page === previousPage) {
      // Yeah, the vue router doesn't like it if you try to navigate to the same route
      return;
    }

    try {
      this.$router.push({ name: 'training_link', params: { page } });
    } catch (ignore) {}
  }
}
</script>
<style scoped></style>
