<template>
  <b-tab ref="tab" title="Class Registration" lazy class="p-0">
    <b-card no-body class="border-0">
      <b-tabs card small v-model="tabIndex">
        <request-status-view @switch-tab="switchTab" />
        <submit-request-view />
      </b-tabs>
    </b-card>
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import RequestStatusView from '@/pages/training/views/RequestStatusView';
import SubmitRequestView from '@/pages/training/views/SubmitRequestView';

@Component({
  components: {
    RequestStatusView,
    SubmitRequestView
  }
})
export default class Registration extends Vue {
  activeTab = 'requests';
  tabs = ['requests', 'submit'];

  get tabIndex() {
    return Math.max(this.tabs.indexOf(this.activeTab), 0);
  }

  set tabIndex(idx) {
    try {
      // This /could/ throw a array index out-of-bounds error
      this.activeTab = this.tabs[idx];
    } catch (ignore) {
      // So just select the first should it for some reason fail
      this.activeTab = this.tabs[0];
    }
  }

  switchTab(tab) {
    this.activeTab = tab;
  }

  activate() {
    this.$refs.tab.activate();
  }
}
</script>
<style scoped></style>
