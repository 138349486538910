import _ from 'underscore';
import { format as formatDate } from '@/util/date';
import { Address as Address } from '@/model/address';

const PhoneNumberFormatter = {
  methods: {
    formatPhoneNumber: function (phoneNumber) {
      const ph = (phoneNumber || '').trim().replace(/[^0-9]/g, '');
      return _(ph).isEmpty() ? '' : ph.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    }
  }
};

const AddressFormatter = {
  methods: {
    formatAddress: function (address, openTag = '<div>', closeTag = '</div>') {
      if (!(address instanceof Address)) {
        return null;
      }
      const lines = [];

      if (address.address1) {
        lines.push(address.address1);
      }

      if (address.address2) {
        lines.push(address.address2);
      }

      if (address.city || address.state || address.zip) {
        const segments = [];

        if (address.city) {
          segments.push(address.city);

          if (address.state) {
            // If Both city and state are available, we need to separate them with a comma
            segments[0] += ',';
          }
        }

        if (address.state) {
          segments.push(address.state);
        }

        if (address.zip) {
          segments.push(address.zip);
        }

        lines.push(segments.join(' '));
      }

      return `${openTag}${lines.join(closeTag + openTag)}${closeTag}`;
    }
  }
};

const DateFormatter = {
  methods: {
    formatDate: function (date, pattern = 'yyyy/MM/dd HH:mm:ss') {
      return formatDate(date, pattern);
    }
  }
};

export { PhoneNumberFormatter, AddressFormatter, DateFormatter };
