<template>
  <b-tab ref="tab" title="Membership" lazy class="p-0">
    <membership-information />
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import _ from 'underscore';
import MembershipInformation from '@/pages/training/components/MembershipInformation';

const NYCVDC = 'UBC04112';

@Component({
  components: { MembershipInformation }
})
export default class MembershipView extends Vue {
  showMembershipInformation = true;
  showStatusInformation = true;

  get user() {
    return this.$store.getters['session/currentUser'] || {};
  }

  get ubcId() {
    return this.user.ubcId;
  }

  get member() {
    const mem = this.$store.getters.currentMember;
    return !!mem && mem.isValid ? mem : {};
  }

  get trainingCenter() {
    const tc = this.$store.getters.trainingCenter;
    return !!tc && tc.isValid ? tc : {};
  }

  get canUpdateAddress() {
    return true === this.member.canUpdateAddress;
  }

  get tcCanUpdateAddress() {
    return true === this.member.tcCanUpdateAddress;
  }

  get nycMustUpdateAddress() {
    return NYCVDC === this.member.council;
  }

  get toUpdatePfyData() {
    switch (true) {
      case this.canUpdateAddress:
        return 'click or tap on the Update Contact Information link found under the Shortcuts &amp; Links to Other Websites section above.';
      case this.tcCanUpdateAddress:
        return `please contact your Local Training Center at ${this.formattedPhoneNumber(this.trainingCenter?.phoneNumber)}`;
      case this.nycMustUpdateAddress:
        return `please conact your Council at ${this.formattedPhoneNumber(this.member?.councilPhoneNumber)}.`;
      default:
        const localPhNum = '4321';
        return `please contact your Local Union at ${localPhNum}`;
    }
  }

  formattedPhoneNumber(phoneNumber) {
    const ph = (phoneNumber || '').trim();
    return _(ph).isEmpty() ? '' : ph.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
  }

  activate() {
    this.$refs.tab.activate();
  }
}
</script>
<style scoped>
.emergency-contact {
  text-decoration: underline;
}
</style>
