<template>
  <b-badge pill variant="danger">You Are Here</b-badge>
</template>
<script>

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class YouAreHere extends Vue {
  
}

</script>