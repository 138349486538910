<template>
    <b-modal ref="modal"
             title="Cancel Registration Request"
             header-bg-variant="dark"
             body-bg-variant="light"
             body-text-variant="dark"
             header-text-variant="light"
             footer-bg-variant="dark">
        <b-row>
            <b-col class="font-weight-bold">
                Please confirm that you want to cancel your request.
            </b-col>
        </b-row>
        <hr/>
        <b-table-simple striped borderless size="sm">
            <b-tr>
                <b-th class="class-info text-right p-0">Class:</b-th>
                <b-td class="class-info p-0 pl-2">{{selectedClass.className}}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Class Dates:</b-th>
                <b-td class="class-info p-0 pl-2">
                    {{ selectedClass.startDate | formatDate}} - {{ selectedClass.endDate | formatDate}}
                </b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Training Center:</b-th>
                <b-td class="class-info p-0 pl-2">{{ selectedClass.trainingCenterName}}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Class Craft:</b-th>
                <b-td class="class-info p-0 pl-2">{{ selectedClass.craft }}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Meeting Times:</b-th>
                <b-td class="class-info p-0 pl-2">{{ selectedClass.meetingTimes }}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Seats Available:</b-th>
                <b-td class="class-info p-0 pl-2">{{ selectedClass.seatsAvailable }}</b-td>
            </b-tr>
        </b-table-simple>
        <hr/>
        <div>
            Click <b-badge variant="success">Yes</b-badge> to cancel the Registration Request, or
            <b-badge variant="danger">No</b-badge> if you do not wish to cancel.
        </div>
        <template #modal-footer="{close}">
            <b-button size="sm" variant="success" @click="sendCancel">Yes</b-button>
            <b-button size="sm" variant="danger" @click="close()">No</b-button>
        </template>
    </b-modal>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class CancellationModal extends Vue {

    @Prop({}) clazz;

    get selectedClass() {
        return this.clazz || {};
    }

    show() {
        this.$refs.modal.show();
    }

    hide() {
        this.$refs.modal.hide();
    }

    sendCancel() {
        this.$emit('cancel', this.selectedClass.id);
    }
}

</script>
<style scoped>

</style>