<template>
  <b-tab ref="tab" title="Apprenticeship" lazy class="p-0">
    <apprenticeship-information />
    <upgrade-information />
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import ApprenticeshipInformation from '@/pages/training/components/ApprenticeshipInformation';
import UpgradeInformation from '@/pages/training/components/UpgradeInformation';

@Component({
  components: { ApprenticeshipInformation, UpgradeInformation }
})
export default class ApprenticeshipView extends Vue {

  activate() {
    this.$refs.tab.activate();
  }
}
</script>
