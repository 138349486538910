<template>
  <b-tab ref="tab" title="Required Curriculum" lazy @click="loadCurriculum" class="p-0">
    <b-card no-body class="border-0">
      <template #header><strong>Required Curriculum</strong></template>
      <b-table
        :fields="fields"
        :items="tableData"
        striped
        small
        head-variant="dark"
        stacked="md"
        show-empty
        empty-text="No Required Curriculum Found">
        <template #cell(className)="row">
          <strong>{{ row.item.className }}</strong><br />{{ row.item.programNames }}
        </template>
        <template #cell(startDate)="row">{{ row.item.startDate | formatDate }}</template>
        <template #cell(endDate)="row">{{ row.item.endDate | formatDate }}</template>
      </b-table>
    </b-card>
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class RequiredCurriculumView extends Vue {
  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get trainingCenter() {
    return this.$store.getters.trainingCenter || {};
  }

  get ubcId() {
    return this.currentMember.ubcId;
  }

  get jatcId() {
    return this.trainingCenter.jatcId;
  }

  get fields() {
    return [
      {
        key: 'className',
        label: 'Class / Program Name',
        sortable: true
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true
      },
      {
        key: 'startDate',
        label: 'Class Begin',
        sortable: true
      },
      {
        key: 'endDate',
        label: 'Class End',
        sortable: true
      },
      {
        key: 'finalGrade',
        label: 'Grade',
        sortable: true
      },
      {
        key: 'classCredit',
        label: 'Class Credit',
        sortable: true
      },
      {
        key: 'year',
        label: 'Year',
        sortable: true
      }
    ];
  }

  get tableData() {
    return this.curriculum;
  }

  get curriculum() {
    return this.$store.getters['omr/curriculum'] || [];
  }

  async loadCurriculum() {
    const ubcId = this.ubcId;
    const jatcId = this.jatcId;

    if (!ubcId || !jatcId) {
      return;
    }

    await this.$store.dispatch('omr/loadRequiredCurriculum', { ubcId, jatcId });
  }

  activate() {
    this.$refs.tab.activate();
  }
}
</script>
<style scoped></style>
