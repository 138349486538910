<template>
  <b-card class="link-card m-0 border-0" footer-class="you-are-here-badge" align="center">
    <b-link :to="hasTo ? to : undefined" :href="href" :target="target">
      <b-img :src="img" :style="{ width: imgWidth, height: imgHeight }" v-if="hasImg" />
      <b-icon :icon="icon" class="link-icon" v-else />
      <div>
        <slot>{{ label }}</slot>
      </div>
    </b-link>
    <template #footer>
      <you-are-here v-if="here" />
    </template>
  </b-card>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'underscore';
import YouAreHere from './YouAreHere';

@Component({
  components: { YouAreHere }
})
export default class QuickLink extends Vue {
  @Prop({ type: String, default: () => null }) icon;
  @Prop({ type: String, default: () => null }) img;
  @Prop({ type: String, default: () => null }) imgWidth;
  @Prop({ type: String, default: () => null }) imgHeight;
  @Prop({ type: Object, default: () => ({}) }) to;
  @Prop({ type: String, default: () => null }) href;
  @Prop({ type: String, default: () => null }) target;
  @Prop({ type: String, default: () => null }) label;

  get here() {
    const route = { name: this.$route.name, params: { page: this.$route.params?.page } };
    const to = { name: this.to?.name, params: { page: this.to?.params?.page } };
    return _(route).isEqual(to);
  }

  get hasTo() {
    return !!this.to?.name;
  }

  get hasImg() {
    return !!this.img;
  }
}
</script>
<style scoped>
.link-card {
  min-width: 20%;
  max-width: 20%;
  cursor: pointer;
}

.link-card a {
  color: black;
}

.link-icon {
  width: 75px;
  height: 75px;
}

.you-are-here-badge {
  padding: 0;
  border: 0;
  background: none;
}
</style>
