import _ from 'underscore';
import { trimToNull } from './text';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export function regexValidator(value, regex, required = true) {
  if (!required && null === trimToNull(value)) {
    return true;
  }

  if (!_(value).isString()) {
    return false;
  }

  const re = regex instanceof RegExp ? regex : _(regex).isString() ? new RegExp(regex) : null;
  return re?.test?.(value) ?? false;
}

export function emailValidator(email, required = true) {
  return regexValidator(email, EMAIL_REGEX, required);
}
