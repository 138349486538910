<template>
  <b-tab title="Submit Request" :active="active" class="p-0">
    <b-card no-body header-bg-variant="warning" class="border-0">
      <template #header>
        <div class="filter-heading clearfix" @click="filtersVisible = !filtersVisible">
          <strong><b-icon-filter /> Filter Available Classes</strong>
          <span class="float-right">
            <b-icon-chevron-double-up v-if="filtersVisible" />
            <b-icon-chevron-double-down v-else />
          </span>
        </div>
      </template>
      <b-collapse v-model="filtersVisible">
        <b-row class="m-2">
          <b-col md="4">
            <b-form-group label="Class Start Date ..." label-size="lg" label-class="font-weight-bold">
              <b-form-group
                label="... is After:"
                description="Filtered classes will only include classes with a Start Date following this date."
                label-cols="4"
                content-cols="8"
                label-align="right">
                <picker-of-dates size="sm" placeholder="Select a Date" format="M/d/yyyy" v-model="filters.startDate" />
              </b-form-group>
              <b-form-group
                label="... is Before:"
                description="Filtered classes will only include classes with a Start Date prior to this date."
                label-cols="4"
                content-cols="8"
                label-align="right">
                <picker-of-dates size="sm" placeholder="Select a Date" format="M/d/yyyy" v-model="filters.endDate" />
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Class Name"
              description="Filtered classes will include only classes whose name contains this Search Criteria"
              label-size="lg"
              label-class="font-weight-bold">
              <b-input-group size="sm">
                <b-input placeholder="Class Name" list="class-names" v-model="filters.className" debounce="500" trim />
                <b-datalist id="class-names" :options="classNameFilterOptions" size="5" />
                <b-input-group-append>
                  <b-button variant="secondary" @click="filters.className = null">
                    <b-icon-x />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card>
    <b-card no-body class="border-0">
      <template #header>
        <b-row>
          <b-col class="header-text">Available Classes</b-col>
          <b-col class="text-center">
            <b-pagination size="sm" v-model="page.current" :total-rows="numRows" :per-page="page.size" />
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" @click="collapseAll" v-if="allExpanded">Collapse All Classes</b-button>
            <b-button size="sm" @click="expandAll" v-else>Expand All Classes</b-button>
            <b-button size="sm" class="ml-2" @click="printAvailable">Print Available Classes</b-button>
          </b-col>
        </b-row>
      </template>
      <b-table :fields="fields" :items="tableData" :per-page="page.size" :current-page="page.current" striped small>
        <template #cell(className)="row">
          <b-link class="class-heading ml-3" :class="row.item._showDetails ? 'font-weight-bold' : ''" @click="row.toggleDetails">
            <b-icon :icon="'arrow-' + (row.item._showDetails ? 'down' : 'right') + '-circle'" class="mr-3" />
            {{ row.item.className }}
          </b-link>
        </template>
        <template #row-details="row">
          <b-list-group flush>
            <b-list-group-item v-for="session in getClassSessions(row.item)" :key="session.classScheduleId">
              <b-row>
                <b-col class="clearfix">
                  <strong>{{ session.className }}</strong>
                  ({{ session.startDate | formatDate }} - {{ session.endDate | formatDate }})
                  <b-link class="float-right" @click="openRegistrationModal(session)">Request</b-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-badge variant="light"> Class Craft: {{ session.craft }} </b-badge> |
                  <b-badge variant="light"> Meeting Times: {{ session.meetingTimes }} </b-badge> |
                  <b-badge variant="light"> Seats Available: {{ session.seatsAvailable }} </b-badge>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </template>
      </b-table>
      <registration-modal ref="registration-modal" :clazz="selectedClass" @request="onMakeRequest" />
    </b-card>
  </b-tab>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import _ from 'underscore';
import RegistrationModal from '@/pages/training/components/RegistrationModal';
import PickerOfDates from '@/components/PickerOfDates';
import { isBefore, isAfter } from 'date-fns';
import downloader from '@/util/downloader';

@Component({
  components: {
    RegistrationModal,
    PickerOfDates
  }
})
export default class SubmitRequestView extends Vue {
  selectedClass = null;
  filtersVisible = false;

  page = {
    current: 1,
    size: 10
  };

  get numRows() {
    return this.tableData.length;
  }

  get numPages() {
    return Math.ceil(this.numRows / this.page.size);
  }

  filters = {
    startDate: null,
    endDate: null,
    className: null
  };

  @Prop({ type: Boolean, default: false }) active;

  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get trainingCenter() {
    return this.$store.getters.trainingCenter || {};
  }

  get ubcId() {
    return this.currentMember.ubcId;
  }

  get jatcId() {
    return this.trainingCenter.jatcId;
  }

  get classNameFilterOptions() {
    return _.chain(this.availableClasses)
      .uniq((c) => c.classId)
      .map((c) => c.className)
      .sortBy((cn) => cn)
      .value();
  }

  get availableClasses() {
    return this.$store.getters['omr/availableClasses'];
  }

  get fields() {
    return [
      {
        key: 'className',
        label: 'Class Name',
        sortable: true
      }
    ];
  }

  get tableData() {
    const classes = this.availableClasses;

    return _.chain(classes)
      .filter(this.filterClasses)
      .uniq((c) => c.classId)
      .sortBy((c) => c.className)
      .value();
  }

  get allExpanded() {
    return _(this.availableClasses).every((c) => c._showDetails);
  }

  expandAll() {
    this.availableClasses.forEach((c) => (c._showDetails = true));
  }

  collapseAll() {
    this.availableClasses.forEach((c) => (c._showDetails = false));
  }

  @Watch('ubcId')
  onUbcIdChange() {
    this.loadAvailableClasses();
  }

  @Watch('jatcId')
  onJatcIdChange() {
    this.loadAvailableClasses();
  }

  filterClasses(clazz) {
    const substring = (this.filters.className || '').trim().toLowerCase();
    if (!_.isEmpty(substring)) {
      const cn = (clazz.className || '').trim().toLowerCase();

      if (!cn.includes(substring)) {
        return false;
      }
    }

    const fsd = _.isDate(this.filters.startDate) && 'Invalid Date' !== this.filters.startDate.toString() ? this.filters.startDate : null;
    if (null !== fsd && isBefore(clazz.startDate, fsd)) {
      return false;
    }

    const fed = _.isDate(this.filters.endDate) && 'Invalid Date' !== this.filters.endDate.toString() ? this.filters.endDate : null;
    if (null !== fed && isAfter(clazz.startDate, fed)) {
      return false;
    }

    return true;
  }

  getClassSessions(clazz) {
    return _.chain(this.availableClasses)
      .filter((c) => c.classId === clazz.classId)
      .filter(this.filterClasses)
      .sortBy((c) => c.startDate)
      .value();
  }

  loadAvailableClasses() {
    const ubcId = this.ubcId;
    const jatcId = this.jatcId;

    if (!ubcId || !jatcId) {
      return; // We need both
    }

    this.$store.dispatch('omr/loadAvailableClasses', { ubcId, jatcId });
  }

  openRegistrationModal(session) {
    this.selectedClass = session;
    this.$refs['registration-modal'].show();
  }

  async onMakeRequest(classScheduleId) {
    const ubcId = this.currentMember.ubcId;

    try {
      await this.$store.dispatch('omr/makeRequest', { ubcId, classScheduleId });
      this.$refs['registration-modal'].hide();
    } catch (err) {
      // TODO: Notify the user
      console.error(err);
    }
  }

  async printAvailable() {
    try {
      await downloader.get(`/api/report/omr/registration/availableclasses/${this.ubcId}`, 'available_classes.pdf');
    } catch (err) {
      this.$bvToast.toast(err.message, {
        title: 'Error',
        solid: true,
        variant: 'danger'
      });
    }
  }

  mounted() {
    this.loadAvailableClasses();
  }
}
</script>
<style>
div.header-text {
  font-size: 21px;
  font-weight: bold;
}

a.class-heading {
  color: black !important;
}

div.date-picker button {
  padding: 0 10px;
}
</style>
