<template>
    <b-tab title="Request Status" :active="active" class="p-0">
        <b-card no-body class="border-0">
            <template #header>
                <b-row>
                    <b-col class="header-text">Your Registration Requests</b-col>
                    <b-col class="text-right">
                        <b-button size="sm" @click="$emit('switch-tab', 'submit')">View Available Classes</b-button>
                        <b-button size="sm" class="ml-2" @click="printRequests">Print Requests</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-card>
        <b-card no-body class="border-0">
            <b-table :fields="fields" :items="tableData" striped stacked="md" small head-variant="dark">
                <template #cell(className)="row">
                    <div>
                        <strong>{{row.item.className}}</strong>
                        ({{row.item.startDate | formatDate}} - {{row.item.endDate | formatDate}})
                    </div>
                    <div>
                        <b-badge variant="light">Class Craft: {{row.item.craft}}</b-badge> |
                        <b-badge variant="light">Meeting Times: {{row.item.meetingTimes}}</b-badge>
                    </div>
                    <div>{{ row.item.trainingCenterName}}</div>
                </template>
                <template #cell(status)="row">
                    {{row.item.status}}
                </template>
                <template #cell(requestDate)="row">
                    {{row.item.requestDate | formatDate}}
                </template>
                <template #cell(processedDate)="row">
                    {{row.item.processedDate | formatDate}}
                </template>
                <template #cell(buttons)="row">
                    <b-link @click="openCancellationModal(row.item)" v-if="isCancellable(row.item)">[Cancel Request]</b-link>
                </template>
            </b-table>
            <cancellation-modal ref="cancellation-modal" :clazz="selectedClass" @cancel="onCancel"/>
        </b-card>
    </b-tab>
</template>
<script>

import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import _ from 'underscore';
import CancellationModal from '@/pages/training/components/CancellationModal';
import downloader from '@/util/downloader';

@Component({
    components: {
        CancellationModal
    }
})
export default class RequestStatusView extends Vue {
    @Prop({type: Boolean, default: false}) active;

    selectedClass = null;

    get currentMember() {
        return this.$store.getters.currentMember || {};
    }

    get ubcId() {
        return this.currentMember.ubcId;
    }

    get requests() {
        return this.$store.getters['omr/requests'] || {};
    }

    get fields() {
        return [{
            key: 'className',
            label: 'Class Name',
            sortable: true
        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }, {
            key: 'requestDate',
            label: 'Requested',
            sortable: true
        }, {
            key: 'processedDate',
            label: 'Processed',
            sortable: true
        }, {
            key: 'buttons',
            label: '',
            sortable: false
        }];
    }

    get tableData() {

        return _.chain(this.requests)
                .map(_.id)
                .sortBy(r => r.class_name)
                .value();
    }

    openCancellationModal(clazz) {
        this.selectedClass = clazz;
        this.$refs['cancellation-modal'].show();
    }

    async loadRequests() {
        const ubcId = this.ubcId;

        if (!ubcId) {
            return;
        }

        await this.$store.dispatch('omr/loadRequests', {ubcId});
    }

    @Watch('ubcId')
    onUbcIdChange() {
        this.loadRequests();
    }

    isCancellable(request) {
        return 'SUBMITTED' === (request.status || '').toUpperCase() && null === request.processedDate;
    }

    async onCancel(requestId) {

        try {

            this.$store.dispatch('omr/cancelRequest', {
                ubcId: this.ubcId,
                requestId
            });

            this.$refs['cancellation-modal'].hide();

        } catch (err) {
            // TODO:
            console.error(err);
        }
    }

    async printRequests() {

        try {

            await downloader.get(`/api/report/omr/registration/registrationrequests/${this.ubcId}`, 'registration_requests.pdf');

        } catch (err) {
            this.$bvToast.toast(err.message, {
                title: 'Error',
                solid: true,
                variant: 'danger'
            });
        }
    }

    mounted() {
        this.loadRequests();
    }
}

</script>
<style scoped>

</style>
