<template>
  <b-card no-body class="border-0">
    <b-card-header>
      <div class="d-flex">
        <strong class="flex-grow-1">Apprenticeship Information</strong>
        <b-button variant="link" @click="show = !show">
          <b-icon :icon="'chevron-double-' + (show ? 'up' : 'down')" />
        </b-button>
      </div>
    </b-card-header>
    <b-collapse v-model="show">
      <b-row no-gutters cols-md="2" cols="1">
        <b-col class="p-2">
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.LEVEL, fieldValueKeys.LEVEL.LEVEL)">
            <b-col><strong>Current Level:</strong></b-col>
            <b-col>{{ member.currentLevel }}</b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.LEVEL, fieldValueKeys.LEVEL.PERCENT)">
            <b-col><strong>Current Percent:</strong></b-col>
            <b-col>{{ !!member.currentPercent ? member.currentPercent + '%' : '' }}</b-col>
          </b-row>
          <b-row cols="2">
            <b-col><strong>Wage Rate:</strong></b-col>
            <b-col>{{ member.wageRate }}</b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.LEVEL, fieldValueKeys.LEVEL.LAST_WORK_MONTH )">
            <b-col><strong>Last Work Month:</strong></b-col>
            <b-col>{{ formatDate(member.lastWorkMonth, 'MMMM yyyy') }}</b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.LEVEL, fieldValueKeys.LEVEL.WORK_HOURS)">
            <b-col><strong>Total Work Hours:</strong></b-col>
            <b-col>{{ member.totalWorkHours }}</b-col>
          </b-row>
          <b-row cols="2" v-if="showTotal">
            <b-col>
              <strong>{{ totalLabel }}:</strong>
            </b-col>
            <b-col>{{ member.classTotal }}</b-col>
          </b-row>
          <b-row cols="2" v-if="hasDisplayField(fieldKeys.LEVEL, fieldValueKeys.LEVEL.WORK_HOURS_SINCE_UPGRADE)">
            <b-col><strong>Total Work Hours since Last Upgrade:</strong></b-col>
            <b-col>{{ member.workHoursSinceUpgrade }}</b-col>
          </b-row>
        </b-col>
        <b-col class="p-2"> </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import { format as formatDate } from '@/util/date';
import { DisplayField } from '@/pages/training/mixins/display_field';

@Component({
  mixins: [DisplayField]
})
export default class ApprenticeshipInformation extends Vue {
  show = true;

  get totalLabel() {
    switch ((this.member.classTypeRequirement || '').toUpperCase()) {
      case 'COMBINED':
        return 'Total Combined Work/Class Hours';
      case 'CREDITS':
        return 'Total Class Credits';
      case 'UNITS':
        return 'Total Class Units';
      default:
        return 'Total Class Hours';
    }
  }

  get showTotal() {
    const keys = this.fieldValueKeys.LEVEL;
    return [keys.CLASS_HOURS, keys.CLASS_CREDITS, keys.CLASS_UNITS, keys.COMBINED_HOURS].some((key) =>
      this.hasDisplayField(this.fieldKeys.LEVEL, key)
    );
  }

  formatDate(date, pattern) {
    return formatDate(date, pattern);
  }
}
</script>
