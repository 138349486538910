<template>
  <b-tab ref="tab" title="My Training History" lazy class="p-0">
    <b-card no-body class="border-0">
      <template #header>
        <div class="d-flex">
          <strong class="flex-grow-1">Training History</strong>
          <b-button variant="link" @click="toggleSection('trainingHistory')">
            <b-icon :icon="'chevron-double-' + (visibility.trainingHistory ? 'up' : 'down')" />
          </b-button>
        </div>
      </template>
      <b-collapse v-model="visibility.trainingHistory">
        <b-table
          :fields="fields.history"
          :items="tableData.history"
          striped
          small
          head-variant="dark"
          stacked="md"
          show-empty
          empty-text="No Training History Found">
          <template #cell(endDate)="row">{{ row.item.endDate | formatDate }}</template>
          <template #cell(expirationDate)="row">{{ row.item.expirationDate | formatDate }}</template>
        </b-table>
      </b-collapse>
    </b-card>
    <b-card no-body class="border-0">
      <template #header>
        <div class="d-flex">
          <strong class="flex-grow-1">Welding Certifications</strong>
          <b-button variant="link" @click="toggleSection('welding')">
            <b-icon :icon="'chevron-double-' + (visibility.welding ? 'up' : 'down')" />
          </b-button>
        </div>
      </template>
      <b-collapse v-model="visibility.welding">
        <b-table
          :fields="fields.welding"
          :items="tableData.welding"
          striped
          small
          head-variant="dark"
          stacked="md"
          show-empty
          empty-text="No Welding Certifications Found">
          <template #cell(id)="row">
            {{ row.item.id }}
            <template v-if="!!row.item.number">
              <br />
              {{ row.item.number }}
            </template>
          </template>
          <template #cell(certificateDate)="row">
            {{ row.item.certificateDate | formatDate }}
          </template>
          <template #cell(expirationDate)="row">
            {{ row.item.expirationDate | formatDate }}
          </template>
        </b-table>
      </b-collapse>
    </b-card>
    <b-card no-body class="border-0">
      <template #header>
        <div class="d-flex">
          <strong class="flex-grow-1">Additional Qualifications / Certifications</strong>
          <b-button variant="link" @click="toggleSection('additional')">
            <b-icon :icon="'chevron-double-' + (visibility.additional ? 'up' : 'down')" />
          </b-button>
        </div>
      </template>
      <b-collapse v-model="visibility.additional">
        <b-table
          :fields="fields.additionalQualCert"
          :items="tableData.additionalQualCert"
          striped
          small
          head-variant="dark"
          stacked="md"
          show-empty
          empty-text="No Additional Qualifications or Certifications Found.">
          <template #cell(certificateDate)="row">
            {{ row.item.certificateDate | formatDate }}
          </template>
          <template #cell(expirationDate)="row">
            {{ row.item.expirationDate | formatDate }}
          </template>
        </b-table>
      </b-collapse>
    </b-card>
    <b-card no-body class="border-0">
      <template #header>
        <div class="d-flex">
          <strong class="flex-grow-1">Upcoming Classes</strong>
          <b-button variant="link" @click="toggleSection('upcoming')">
            <b-icon :icon="'chevron-double-' + (visibility.upcoming ? 'up' : 'down')" />
          </b-button>
        </div>
      </template>
      <b-collapse v-model="visibility.upcoming">
        <b-table
          :fields="fields.upcoming"
          :items="tableData.upcoming"
          striped
          small
          head-variant="dark"
          stacked="md"
          show-empty
          empty-text="No Upcoming Classes Found.">
          <template #cell(startDate)="row">
            {{ row.item.startDate | formatDate }}
          </template>
          <template #cell(endDate)="row">
            {{ row.item.endDate | formatDate }}
          </template>
        </b-table>
      </b-collapse>
    </b-card>
    <b-card no-body class="border-0" v-if="isLmsEligible">
      <template #header>
        <div class="d-flex">
          <strong class="flex-grow-1">LMS Updates/Notices</strong>
          <b-button variant="link" @click="toggleSection('lmsUpdates')">
            <b-icon :icon="'chevron-double-' + (visibility.lmsUpdates ? 'up' : 'down')" />
          </b-button>
        </div>
      </template>
      <b-collapse v-model="visibility.lmsUpdates">
        <b-table
          :fields="fields.lmsUpdates"
          :items="tableData.lmsUpdates"
          striped
          small
          head-variant="dark"
          stacked="md"
          show-empty
          empty-text="No LMS Updates/Notices Found Found.">
        </b-table>
      </b-collapse>
    </b-card>
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import _ from 'underscore';

@Component
export default class TrainingHistoryView extends Vue {
  visibility = {
    trainingHistory: false,
    welding: false,
    additional: false,
    upcoming: false,
    lmsUpdates: false
  };

  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get ubcId() {
    return this.currentMember.ubcId;
  }

  get isLmsEligible() {
    return this.currentMember.lmsEligible;
  }

  get trainingHistory() {
    return _.chain(this.$store.getters['omr/trainingHistory']).value();
  }

  get certifications() {
    return this.$store.getters['omr/certifications'] || {};
  }

  get upcomingClasses() {
    return this.$store.getters['omr/upcomingClasses'] || [];
  }

  get lmsUpdates() {
    return this.$store.getters['omr/lmsUpdates'] || [];
  }

  get fields() {
    return {
      history: [
        {
          key: 'className',
          label: 'Class Name',
          sortable: true
        },
        {
          key: 'endDate',
          label: 'Date',
          sortable: true
        },
        {
          key: 'expirationDate',
          label: 'Expiration Date',
          sortable: true
        }
      ],
      welding: [
        {
          key: 'name',
          label: 'Welding Process',
          sortable: true
        },
        {
          key: 'id',
          label: 'Certification ID',
          sortable: true
        },
        {
          key: 'certificateDate',
          label: 'Certification Date',
          sortable: true
        },
        {
          key: 'expirationDate',
          label: 'Expiration Date',
          sortable: true
        }
      ],
      additionalQualCert: [
        {
          key: 'name',
          label: 'Qualification / Certification Name',
          sortable: true
        },
        {
          key: 'certificateDate',
          label: 'Date Issued',
          sortable: true
        },
        {
          key: 'expirationDate',
          label: 'Qual / Cert Expiration',
          sortable: true
        },
        {
          key: 'number',
          label: 'Qual / Cert Number',
          sortable: true
        }
      ],
      upcoming: [
        {
          key: 'className',
          label: 'Class Name',
          sortable: true
        },
        {
          key: 'startDate',
          label: 'Start Date',
          sortable: true
        },
        {
          key: 'endDate',
          label: 'End Date',
          sortable: true
        },
        {
          key: 'meetingTimes',
          label: 'Meeting Times',
          sortable: true
        },
        {
          key: 'location',
          label: 'Location',
          sortable: true
        }
      ],
      lmsUpdates: [
        {
          key: 'name',
          label: 'Update/Notice',
          sortable: true
        },
        {
          key: 'completionDate',
          label: 'Date',
          sortable: true
        }
      ]
    };
  }

  get tableData() {
    const history = this.trainingHistory;
    const welding = this.certifications.welding;
    const additionalQualCert = this.certifications.additional;
    const upcoming = this.upcomingClasses;
    const lmsUpdates = this.lmsUpdates;

    return {
      history,
      welding,
      additionalQualCert,
      upcoming,
      lmsUpdates
    };
  }

  async toggleSection(section) {
    if (!_.has(this.visibility, section)) {
      return;
    }

    if (this.visibility[section]) {
      return (this.visibility[section] = false);
    }

    const ubcId = this.ubcId;

    if (!ubcId) {
      return;
    }

    switch (section) {
      case 'trainingHistory':
        await this.$store.dispatch('omr/loadTrainingHistory', { ubcId });
        this.visibility.trainingHistory = true;
        break;

      case 'welding':
      case 'additional':
        await this.$store.dispatch('omr/loadCertifications', { ubcId });
        this.visibility[section] = true;
        break;

      case 'upcoming':
        await this.$store.dispatch('omr/loadUpcomingClasses', { ubcId });
        this.visibility[section] = true;
        break;

      case 'lmsUpdates':
        await this.$store.dispatch('omr/loadLmsUpdates', { ubcId });
        this.visibility[section] = true;
        break;

      default:
        this.visibility[section] = true;
        break;
    }
  }

  activate() {
    this.$refs.tab.activate();
  }
}
</script>
<style scoped></style>
