<template>
  <b-card no-body class="border-0">
    <b-card-header>
      <div class="d-flex">
        <strong class="flex-grow-1">Membership Information</strong>
        <b-button variant="link" @click="show = !show">
          <b-icon :icon="'chevron-double-' + (show ? 'up' : 'down')" />
        </b-button>
      </div>
    </b-card-header>
    <b-collapse v-model="show">
      <b-row no-gutters cols-md="3" cols="1">
        <b-col class="p-2">
          <b-alert show variant="warning">
            To update your Primary Address, Phone Number or Email Address
            <template v-if="$store.getters.canUpdateAddress">
              click or tap
              <b-link :to="{ name: 'contact-information-form' }">here</b-link>.
            </template>
            <template v-else>{{ toUpdatePfyData }}</template>
          </b-alert>
        </b-col>
        <b-col
          class="p-2 d-none d-md-block"
          v-if="hasDisplayField(fieldKeys.CONTACT, fieldValueKeys.CONTACT.ALTERNATE_ADDRESS) || showEmergencyContact">
          <b-alert show variant="warning">
            To update your Alternate Address or Emergency Contact, please contact your Local Training Center
            <template v-if="hasTrainingCenter"> at {{ formatPhoneNumber(trainingCenter.phoneNumber) }}. </template>
            <template v-else> . Locate your training center here </template>
          </b-alert>
        </b-col>
        <b-col class="p-2 d-none d-md-block"></b-col>
      </b-row>
      <b-row no-gutters cols-md="3" cols="1">
        <b-col class="px-3 py-0">
          <strong class="d-block">Primary Address</strong>
          <div v-html="formatAddress(member.primaryAddress)"></div>
          <strong class="d-block mt-3">Phone Number</strong>
          <div>{{ formatPhoneNumber(member.primaryPhoneNumber) || 'None on File' }}</div>
          <strong class="d-block mt-3">Email Address</strong>
          <div class="mb-3">{{ member.emailAddress || 'None on File' }}</div>
        </b-col>
        <b-col class="px-3 py-0" v-if="hasDisplayField(fieldKeys.CONTACT, fieldValueKeys.CONTACT.ALTERNATE_ADDRESS) || showEmergencyContact">
          <b-alert show variant="warning" class="d-md-none">
            To update your Alternate Address or Emergency Contact, please contact your Local Training Center at
            {{ formatPhoneNumber(trainingCenter.phoneNumber) }}.
          </b-alert>
          <template v-if="hasDisplayField(fieldKeys.CONTACT, fieldValueKeys.CONTACT.ALTERNATE_ADDRESS)">
            <strong class="d-block">Alternate Address</strong>
            <div v-html="formatAddress(member.alternateAddress)"></div>
          </template>
          <template v-if="showEmergencyContact">
            <strong class="d-block mt-3"><u>Emergency Contact Information</u></strong>
            <div v-if="hasDisplayField(fieldKeys.CONTACT, fieldValueKeys.CONTACT.EMERGENCY_CONTACT_NAME)">
              <strong>Name:</strong> {{ emergencyContact.name }}
            </div>
            <div v-if="hasDisplayField(fieldKeys.CONTACT, fieldValueKeys.CONTACT.EMERGENCY_CONTACT_RELATIONSHIP)">
              <strong>Relationship:</strong> {{ emergencyContact.relationship }}
            </div>
            <div v-if="hasDisplayField(fieldKeys.CONTACT, fieldValueKeys.CONTACT.EMERGENCY_CONTACT_PHONE)">
              <strong>Phone Number:</strong> {{ formatPhoneNumber(emergencyContact.phone) || emergencyContact.phone }}
            </div>
            <div v-if="hasDisplayField(fieldKeys.CONTACT, fieldValueKeys.CONTACT.EMERGENCY_CONTACT_ADDRESS)"><strong>Address:</strong></div>
            <div v-html="formatAddress(emergencyContact.address)"></div>
          </template>
        </b-col>
        <b-col class="px-3 py-0">
          <div v-if="hasDisplayField(fieldKeys.MEMBERSHIP, fieldValueKeys.MEMBERSHIP.SPECIALTIES)"><strong>Specialties:</strong> {{ member.specialties }}</div>
          <div v-if="hasDisplayField(fieldKeys.MEMBERSHIP, fieldValueKeys.MEMBERSHIP.INDENTURE_DATE)">
            <strong>Indenture Date:</strong> {{ formatDate(member.indentDate, 'MMMM d, yyyy') }}
          </div>
          <div v-if="hasDisplayField(fieldKeys.MEMBERSHIP, fieldValueKeys.MEMBERSHIP.CONTRACTOR)"><strong>Contractor:</strong> {{ member.contractor }}</div>
          <div class="mt-3" v-if="hasDisplayField(fieldKeys.MEMBERSHIP, fieldValueKeys.MEMBERSHIP.STATUS)">
            <strong>Student Status:</strong> {{ member.studentStatus }}
          </div>
          <div v-if="showDuesCode"><strong>Dues Code:</strong> {{ member.duesCode }}</div>
          <div v-if="showCurrentBalance">
            <strong>Current Balance: </strong>
            <span :class="{ 'text-success': 0 > currentBalance, 'text-danger': 0 < currentBalance }">
              {{ formattedCurrentBalance }}
            </span>
          </div>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import _ from 'underscore';
import { format as formatDate } from '@/util/date';
import { PhoneNumberFormatter, AddressFormatter, DateFormatter } from '@/util/format';
import { DisplayField } from '@/pages/training/mixins/display_field';

@Component({
  mixins: [PhoneNumberFormatter, AddressFormatter, DateFormatter, DisplayField]
})
export default class MembershipInformation extends Vue {
  show = true;

  get emergencyContact() {
    return this.member.emergencyContact || {};
  }

  get toUpdatePfyData() {
    switch (true) {
      case this.$store.getters.tcCanUpdateAddress:
        return `please contact your Local Training Center at ${this.formatPhoneNumber(this.trainingCenter?.phoneNumber)}.`;
      case this.$store.getters.nycMustUpdateAddress:
        return `please contact your Council at ${this.formatPhoneNumber(this.member?.council?.phoneNumber)}.`;
      default:
        return `please contact your Local Union at ${this.member?.local?.phoneNumber}.`;
    }
  }

  get currentBalance() {
    return parseFloat(this.member.currentBalance);
  }

  get formattedCurrentBalance() {
    const b = this.currentBalance;

    if (isNaN(b)) {
      return '';
    }

    const neg = 0 > b;
    const amount = Math.abs(b);

    return `$${amount.toFixed(2)}${neg ? ' CR' : ''}`;
  }

  get hasTrainingCenter() {
    return !_(this.trainingCenter).isEmpty();
  }

  get council() {
    return this.member?.council || {};
  }

  get showDuesCode() {
    return true === this.council.displayDuesCode;
  }

  get showCurrentBalance() {
    return true === this.council.displayBalanceOwed;
  }

  get showEmergencyContact() {
    const keys = this.fieldValueKeys.CONTACT;
    return [keys.EMERGENCY_CONTACT_NAME, keys.EMERGENCY_CONTACT_RELATIONSHIP, keys.EMERGENCY_CONTACT_PHONE, keys.EMERGENCY_CONTACT_ADDRESS].some(
      (key) => this.hasDisplayField(this.fieldKeys.CONTACT, key)
    );
  }
}
</script>
