<template>
    <b-modal ref="modal"
             title="Please Confirm the Registration Request"
             header-bg-variant="dark"
             body-bg-variant="light"
             body-text-variant="dark"
             header-text-variant="light"
             footer-bg-variant="dark">

        <b-table-simple striped borderless size="sm">
            <b-tr>
                <b-th class="class-info text-right p-0">Class:</b-th>
                <b-td class="class-info p-0 pl-2">{{selectedClass.className}}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Class Dates:</b-th>
                <b-td class="class-info p-0 pl-2">
                    {{ selectedClass.startDate | formatDate}} - {{ selectedClass.endDate | formatDate}}
                </b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Training Center:</b-th>
                <b-td class="class-info p-0 pl-2">{{ trainingCenter.name }}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Class Craft:</b-th>
                <b-td class="class-info p-0 pl-2">{{ selectedClass.craft }}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Meeting Times:</b-th>
                <b-td class="class-info p-0 pl-2">{{ selectedClass.meetingTimes }}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="class-info text-right p-0">Seats Available:</b-th>
                <b-td class="class-info p-0 pl-2">{{ selectedClass.seatsAvailable }}</b-td>
            </b-tr>
        </b-table-simple>
        <hr/>
        <div>
            Click <b-badge variant="success">Yes</b-badge> to confirm the Registration Request,
            or <b-badge variant="danger">No</b-badge> if you do not wish to proceed.
        </div>
        <hr/>
        <div>Once you submit a request, the training center will take the appropriate action.</div>
        <div class="mt-1">
            <strong>IMPORTANT:</strong>
            Submitting a request <span style="text-decoration: underline">does not</span>
            guarantee registration. Please contact your training center for additional
            information about your request.
        </div>

        <template #modal-footer="{close}">
            <b-button size="sm" variant="success" @click="sendRequest">Yes</b-button>
            <b-button size="sm" variant="danger" @click="close">No</b-button>
        </template>
    </b-modal>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class RegistrationModal extends Vue {

    @Prop({}) clazz;

    get selectedClass() {
        return this.clazz || {};
    }

    get member() {
        return this.$store.getters.currentMember || {};
    }

    get trainingCenter() {
        return this.$store.getters.trainingCenter || {};
    }

    get fields() {
        return [{
            key: 'className',
            label: 'Class:',
            sortable: false
        }, {
            key: 'classDates',
            label: 'Class Dates:',
            sortable: false
        }, {
            key: 'trainingCenter',
            label: 'Training Center:',
            sortable: false
        }, {
            key: 'craft',
            label: 'Class Craft',
            sortable: false
        }, {
            key: 'meetingTimes',
            label: 'Meeting Times:',
            sortable: false
        }, {
            key: 'seats',
            label: 'Seats Available:',
            sortable: false
        }];
    }

    show() {
        this.$refs.modal.show();
    }

    hide() {
        this.$refs.modal.hide();
    }

    sendRequest() {
        this.$emit('request', this.selectedClass.classScheduleId);
    }
}

</script>
<style scoped>

    .class-info {
        color: black;
    }


</style>
