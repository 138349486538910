<template>
  <div>
    <quick-links />
    <b-card class="border-0" no-body>
      <template #header>
        <div class="d-flex">
          <strong class="flex-grow-1">{{ title }}</strong>
          <b-button variant="link" @click="showUnionDetails = !showUnionDetails">
            <b-icon :icon="'chevron-double-' + (showUnionDetails ? 'up' : 'down')" />
          </b-button>
        </div>
      </template>
      <b-collapse v-model="showUnionDetails">
        <div class="row">
          <div class="col"><member-details /></div>
          <div class="col"><training-center-details /></div>
        </div>
      </b-collapse>
    </b-card>
    <b-card no-body class="border-0">
      <b-card-header><strong>Update Contact Information</strong></b-card-header>
      <b-card-body>
        <b-card variant="light">
          <b-form-group label="UBC Member ID:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input disabled v-model="member.ubcId" />
          </b-form-group>
          <b-form-group label="Name:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input disabled v-model="member.fullName" />
          </b-form-group>
          <b-form-group label="Email Address:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input :disabled="!emailAddressEditMode" v-model="workspace.emailAddress" :state="emailAddressVerified" placeholder="Email Address" />
            <b-form-invalid-feedback>{{ emailFeedback }}</b-form-invalid-feedback>
            <b-form-valid-feedback>Your Email Address has been verified</b-form-valid-feedback>
          </b-form-group>
          <b-form-group label="" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <template v-if="emailAddressEditMode">
              <b-button variant="success" @click="onEmailChange" :disabled="!isValidEmailAddress">Save</b-button>
              <b-button variant="danger" class="ml-1" @click="onEmailChangeCancel">Cancel</b-button>
            </template>
            <template v-else>
              <b-button variant="primary" @click="emailAddressEditMode = true">Change Your Email Address</b-button>
              <b-button variant="warning" class="ml-1" v-if="!emailAddressVerified" @click="verifyYourEmail">Verify Your Email Address</b-button>
            </template>
          </b-form-group>
          <b-form-group label="Address 1:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input :disabled="!editMode" v-model="address1" :state="validField('address1')" />
            <b-form-invalid-feedback>Address 1 is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Address 2:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input :disabled="!editMode" v-model="address2" :state="validField('address2')" />
          </b-form-group>
          <b-form-group label="City:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input :disabled="!editMode" v-model="city" :state="validField('city')" />
            <b-form-invalid-feedback>City is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="State/Province:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-select :disabled="!editMode" :options="stateOptions" v-model="state" :state="validField('state')" />
            <b-form-invalid-feedback>State/Province is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="ZIP/Postal Code:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input :disabled="!editMode" v-model="zip" :state="validField('zip')" />
            <b-form-invalid-feedback>ZIP/Postal Code is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Country:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input v-model="country" disabled :state="validField('country')" />
            <b-form-invalid-feedback>Country is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Phone:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input :disabled="!editMode" v-model="primaryPhoneNumber" v-mask="'(###) ###-####'" :state="validField('primaryPhoneNumber')" />
            <b-form-invalid-feedback>Please enter a valid Phone Number</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Mobile Phone:" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <b-input :disabled="!editMode" v-model="mobilePhoneNumber" v-mask="'(###) ###-####'" :state="validField('mobilePhoneNumber')" />
            <b-form-invalid-feedback>Please enter a valid Phone Number</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="" label-cols-sm="3" label-cols-md="2" label-align-sm="right" v-if="$store.getters.canUpdateAddress">
            <b-button variant="primary" @click="editMode = true" v-if="!editMode">Edit</b-button>
            <template v-else>
              <b-button variant="success" @click="onSave">Save</b-button>
              <b-button variant="danger" @click="onCancel" class="ml-1">Cancel</b-button>
            </template>
          </b-form-group>
          <b-form-group label="" label-cols-sm="3" label-cols-md="2" label-algin-sm="right" v-else>
            <b-alert show variant="warning"> To update your Primary Address, Phone Number or Email Address, {{ toUpdatePfyData }}. </b-alert>
          </b-form-group>
          <b-form-group label="" label-cols-sm="3" label-cols-md="2" label-align-sm="right">
            <div>
              <b-link @click="$store.commit('toggleChangePasswordModal', true)">Change Password</b-link>
            </div>
            <div>
              <b-link @click="showUsername">Request Username</b-link>
            </div>
          </b-form-group>
        </b-card>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { Vue, Component, Watch } from 'vue-property-decorator';
import _ from 'underscore';
import { PhoneNumberFormatter } from '@/util/format';
import Address from '@/model/address';
import { emailValidator } from '@/util/validator';
import MemberDetails from '@/views/details/MemberDetails';
import TrainingCenterDetails from '@/views/details/TrainingCenterDetails';
import QuickLinks from '@/pages/quick_links/views/QuickLinks';

const requiredFields = ['address1', 'city', 'state', 'zip', 'country'];
const allValidatableFields = [...requiredFields, 'primaryPhoneNumber', 'mobilePhoneNumber'];

@Component({
  components: { MemberDetails, TrainingCenterDetails, QuickLinks },
  mixins: [PhoneNumberFormatter]
})
export default class ContactInformationForm extends Vue {
  showUnionDetails = true;
  editMode = false;
  emailAddressEditMode = false;
  phoneMask = '(###) ###-####';

  get title() {
    return this.$store.getters.title;
  }

  get user() {
    return this.$store.getters['session/currentUser'] || {};
  }

  get workspace() {
    return this.member?.workspace || {};
  }

  get address1() {
    return this.ensureAddress().address1;
  }

  set address1(address1) {
    this.ensureAddress().address1 = address1;
  }

  get address2() {
    return this.ensureAddress().address2;
  }

  set address2(address2) {
    this.ensureAddress().address2 = address2;
  }

  get city() {
    return this.ensureAddress().city;
  }

  set city(city) {
    this.ensureAddress().city = city;
  }

  get state() {
    return this.ensureAddress().state;
  }

  set state(state) {
    this.ensureAddress().state = state;
  }

  get zip() {
    return this.ensureAddress().zip;
  }

  set zip(zip) {
    this.ensureAddress().zip = zip;
  }

  get primaryPhoneNumber() {
    return this.workspace.primaryPhoneNumber;
  }

  set primaryPhoneNumber(primaryPhoneNumber) {
    this.workspace.primaryPhoneNumber = primaryPhoneNumber;
  }

  get mobilePhoneNumber() {
    return this.workspace.mobilePhoneNumber;
  }

  set mobilePhoneNumber(mobilePhoneNumber) {
    this.workspace.mobilePhoneNumber = mobilePhoneNumber;
  }

  get country() {
    const country = this.lookupCountryByAbbreviation(this.ensureAddress().country);
    return country?.name;
  }

  get member() {
    const mem = this.$store.getters.currentMember;
    return !!mem && mem.isValid ? mem : {};
  }

  get toUpdatePfyData() {
    switch (true) {
      case this.$store.getters.tcCanUpdateAddress:
        return `please contact your Local Training Center at ${this.formatPhoneNumber(this.trainingCenter?.phoneNumber)}.`;
      case this.$store.getters.nycMustUpdateAddress:
        return `please contact your Council at ${this.formatPhoneNumber(this.member?.council?.phoneNumber)}.`;
      default:
        return `please contact your Local Union at ${this.member?.local?.phoneNumber}.`;
    }
  }

  get stateOptions() {
    return this.$store.getters.countries.map((c) => ({
      label: c.name,
      options: c.states.map((s) => ({
        value: s.abbreviation,
        text: s.name
      }))
    }));
  }

  get emailAddressVerified() {
    return this.member.emailVerified;
  }

  get emailHasChanges() {
    return this.workspace.emailAddress !== this.member.emailAddress;
  }

  get isValidEmailAddress() {
    return emailValidator(this.workspace.emailAddress, true);
  }

  get emailFeedback() {
    switch (true) {
      case !this.isValidEmailAddress:
        return 'Please enter a valid email address';

      case !this.emailAddressVerified:
        return 'Your Email Address has not been verified';

      default:
        return null;
    }
  }

  validField(field) {
    const isRequired = requiredFields.includes(field);
    const value = this[field];
    const isEmpty = _(value).isEmpty();

    if (isRequired && isEmpty) {
      return false;
    }

    if (field.match(/phone/i)) {
      if (isEmpty) {
        return undefined;
      }
      // TODO: Make sure it's a valid phone number
      const ph = value.replace(/[^\d]/g, '');
      return 10 === ph.length;
    }

    return !isRequired && isEmpty ? undefined : true;
  }

  get validForm() {
    return allValidatableFields.every((f) => {
      const valid = this.validField(f);
      return _(valid).isUndefined() || true === valid;
    });
  }

  @Watch('state')
  onStateChanged(state) {
    const country = this.lookupCountryByState(state);

    if (!!country) {
      this.ensureAddress().country = country.abbreviation;
    }
  }

  lookupCountryByState(stateAbbreviation) {
    return _(this.$store.getters.countries).find((c) => _(c.states).find((s) => s.abbreviation === stateAbbreviation));
  }

  lookupCountryByAbbreviation(abbreviation) {
    return _(this.$store.getters.countries).find((c) => c.abbreviation === abbreviation);
  }

  onCancel() {
    this.editMode = false;
    this.member.rollback();
  }

  async onEmailChange() {
    if (!this.isValidEmailAddress) {
      return;
    }

    if ((this.workspace.emailAddress || '').trim().toLowerCase() === (this.member.emailAddress || '').trim().toLowerCase()) {
      return this.$bvToast.toast('No changes were made', {
        title: 'Warning',
        variant: 'warning',
        solid: true
      });
    }

    try {
      if (!this.isValidEmailAddress) {
        return this.$bvToast.toast('Please correct the highlighted errors before saving', {
          title: 'Validation Error',
          variant: 'danger',
          solid: true
        });
      }

      const payload = {
        ubc_id: this.member.ubcId,
        primary_email_address: this.workspace.emailAddress
      };

      await this.$store.dispatch('saveEmailAddress', payload);

      this.alertEmailVerificationRequest();
      this.workspace.emailVerified = false;
      this.member.emailVerified = false;

      this.member.emailAddress = this.workspace.emailAddress;
      this.emailAddressEditMode = false;
    } catch (err) {
      this.$bvToast.toast(err.message, {
        title: 'Error',
        variant: 'danger',
        solid: true
      });
    }
  }

  async onEmailChangeCancel() {
    this.workspace.emailAddress = this.member.emailAddress;
    this.emailAddressEditMode = false;
  }

  async onSave() {
    if (!this.validForm) {
      return this.$bvToast.toast('Please correct the highlighted errors before saving', {
        title: 'Validation Error',
        variant: 'danger',
        solid: true
      });
    }

    const payload = {
      ubc_id: this.member.ubcId,
      primary_phone_number: this.member.workspace.primaryPhoneNumber,
      mobile_phone_number: this.member.workspace.mobilePhoneNumber,
      primary_address: this.member.workspace.primaryAddress.serialize()
    };

    try {
      await this.$store.dispatch('saveContactInformation', payload);

      this.member.commit();
      this.editMode = false;
      this.$bvToast.toast('You changes have been saved!', {
        title: 'Success',
        variant: 'success',
        solid: true
      });
    } catch (err) {
      this.$bvToast.toast(err.message, {
        title: 'Error',
        variant: 'danger',
        solid: true
      });
    }
  }

  showUsername() {
    this.$store.commit('toggleShowUsernameModal', true);
  }

  ensureAddress() {
    if (!(this.workspace.primaryAddress instanceof Address)) {
      this.workspace.primaryAddress = Address.create({});
    }

    return this.workspace.primaryAddress;
  }

  alertEmailVerificationRequest() {
    this.$bvToast.toast(
      'An email verification request has been sent to this email address.  Please check your inbox.  Once verified, please refresh this page.',
      {
        title: 'Email Verification Request',
        variant: 'success',
        solid: true,
        noAutoHide: true
      }
    );
  }

  async verifyYourEmail() {
    try {
      await this.$store.dispatch('requestEmailVerification', { ubc_id: this.member.ubcId });
      this.alertEmailVerificationRequest();
    } catch (err) {
      this.$bvToast.toast(err.message, {
        title: 'Error',
        variant: 'danger',
        solid: true
      });
    }
  }
}
</script>
